var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideLabel,
              expression: "!hideLabel"
            }
          ],
          staticClass: "form-label",
          attrs: { for: "organization-select" }
        },
        [
          _vm._t("label", function() {
            return [_vm._v("Organization")]
          })
        ],
        2
      ),
      _c("vue-typeahead-bootstrap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.organizationId == null,
            expression: "organizationId == null"
          }
        ],
        attrs: {
          data: _vm.organizations,
          serializer: function(s) {
            return s.name
          },
          inputClass: !_vm.isValid ? "form-control is-invalid" : "form-control",
          inputName: "organization-select",
          debounce: "500"
        },
        on: {
          hit: function($event) {
            return _vm.selectOrganization($event)
          },
          input: function($event) {
            return _vm.createNewOrg()
          }
        },
        model: {
          value: _vm.organizationSearch,
          callback: function($$v) {
            _vm.organizationSearch = $$v
          },
          expression: "organizationSearch"
        }
      }),
      _vm.organizationInfo
        ? _c("div", { staticClass: "input-group" }, [
            _c("span", { staticClass: "form-control alert-info" }, [
              _vm._v(" " + _vm._s(_vm.organizationInfo.name) + " ")
            ]),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clearOrganization()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "x" } })],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.organizationId,
            expression: "organizationId"
          }
        ],
        attrs: { type: "hidden", required: _vm.required },
        domProps: { value: _vm.organizationId },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.organizationId = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }